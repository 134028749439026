<template>
  <div class="v-slots-lunch-mobile">
    <div
      class="v-slots-lunch-mobile-title"
      v-html="slotProduct.Name"
    />
    <div class="v-scrollbar">
      <template v-if="appConfig.VueSettingsPreRun.MenuSlotsLayout === 'Erebor'">
        <common-cards-flex-mesh
          :items="slotProduct.Products"
          :max-items="3"
          :mesh-gap="16"
          :min-items="3"
        >
          <template #item="item: ProductInList">
            <div
              class="v-slot-product-single"
              :class="{ active: selectedSlotId === item.ID }"
              data-test-id="slots-lunch-add-product"
              @click="() => selectSlotLocal(slotProduct?.ID, item)"
            >
              <arora-nuxt-image
                :key="item.ID"
                :image="
                  imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage
                "
                :alt="item.Name"
              />
              <div
                class="v-slot-product-single-title-popup"
                v-html="sanitize(item.Name)"
              />
              <div class="v-slot-product-single-subtitle-popup">
                <common-currency :amount="item.Price" />
              </div>
            </div>
          </template>
        </common-cards-flex-mesh>
      </template>
      <template v-else-if="appConfig.VueSettingsPreRun.MenuSlotsLayout === 'Gladden'">
        <common-cards-flex-mesh
          :items="slotProduct.Products"
          :max-items="1"
          :mesh-gap="16"
        >
          <template #item="item: ProductInList">
            <div
              class="v-slot-product-single v-slot-product-single-gladden"
              :class="{ active: selectedSlotId === item.ID }"
              data-test-id="slots-lunch-add-product"
              @click="() => selectSlotLocal(slotProduct?.ID, item)"
            >
              <arora-nuxt-image
                :key="item.ID"
                :image="
                  imageExists(item.CombineMobileImage) ? item.CombineMobileImage : item.NormalImage
                "
                :alt="item.Name"
              />
              <div class="v-slot-product-single-gladden-more">
                <div
                  class="v-slot-product-single-title-popup"
                  v-html="sanitize(item.Name)"
                />
                <div
                  class="v-slot-product-single-subtitle-popup"
                  v-html="sanitize(item.Description)"
                />
              </div>
            </div>
          </template>
        </common-cards-flex-mesh>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductInList, Slot } from '~types/menuStore'

import type { GUID } from '@arora/common'

const { selectSlot } = defineProps<{
  slotProduct: Slot
  selectedSlotId: GUID | undefined
  selectSlot: (slotId: GUID | undefined, item: ProductInList) => void
}>()

const popupStore = usePopupStore()

const { sanitize } = useI18nSanitized()
const { imageExists } = useImageInfo()
const appConfig = useAppConfig()

function selectSlotLocal(slotId: GUID | undefined, item: ProductInList): void {
  selectSlot(slotId, item)

  popupStore.closePopup()
}
</script>

<style lang="scss">
@use '~/assets/variables';

.v-slots-lunch-mobile {
  .v-slots-lunch-mobile-title {
    font-size: variables.$TextSizeH3;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .v-slot-product-single-gladden {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;

    .v-img-fluid {
      flex: 0 0 30%;
      max-width: 30%;
      height: auto;
    }

    .v-slot-product-single-gladden-more {
      flex: 0 0 calc(70% - 10px);
      max-width: calc(70% - 10px);

      .v-slot-product-single-title-popup {
        text-align: left;
      }

      .v-slot-product-single-subtitle-popup {
        text-align: left;
      }
    }
  }
}

.v-slot-product-single-title-popup {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-slot-product-single-subtitle-popup {
  text-align: center;
  margin: 10px 0;
}
</style>
